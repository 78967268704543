import styled, { keyframes, ThemeProvider } from "styled-components";
import { useTranslation } from "react-i18next";
import { baseTheme } from "../../../theme/Theme";

// Animation
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Thème spécifique pour la page des conditions d'utilisation
const conditionsOfUseTheme = {
  ...baseTheme, // Utilisation du même thème de base que pour la page des mentions légales
  fonts: {
    primary: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    secondary: "'Georgia', serif",
  },
  breakpoints: {
    tablet: "768px",
    desktop: "1024px",
  },
};

// Styled Components
const Container = styled.main`
  animation: ${fadeInUp} 1s ease-out;
  background-color: ${(props) => props.theme.colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 80px 20px;
  width: 100%;
`;

const Title = styled.h1`
  color: ${(props) => props.theme.colors.secondary};
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 3em;
  margin-bottom: 40px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 2.5em;
  }
`;

const Content = styled.section`
  color: ${(props) => props.theme.colors.text};
  font-size: 1.2em;
  line-height: 1.7;
  max-width: 700px;
  padding: 0 15px;
  width: 100%;
`;

const SubHeading = styled.h2`
  color: ${(props) => props.theme.colors.secondary};
  font-size: 2em;
  margin-bottom: 20px;
  margin-top: 30px;
`;

const Paragraph = styled.p`
  margin-bottom: 15px;
`;

const Link = styled.a`
  color: ${(props) => props.theme.colors.secondary};
  &:hover {
    color: ${(props) => props.theme.colors.linkHover};
  }
`;

// Composant ConditionsOfUsePage
const ConditionsOfUsePage = () => {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={conditionsOfUseTheme}>
      <Container>
        <Title>{t("conditionsOfUse.title")}</Title>
        <Content>
          <SubHeading>{t("conditionsOfUse.acceptance.title")}</SubHeading>
          <Paragraph>{t("conditionsOfUse.acceptance.content")}</Paragraph>

          <SubHeading>{t("conditionsOfUse.serviceNature.title")}</SubHeading>
          <Paragraph>{t("conditionsOfUse.serviceNature.content")}</Paragraph>

          <SubHeading>{t("conditionsOfUse.authorizedUse.title")}</SubHeading>
          <Paragraph>{t("conditionsOfUse.authorizedUse.content")}</Paragraph>

          <SubHeading>
            {t("conditionsOfUse.intellectualPropertyRights.title")}
          </SubHeading>
          <Paragraph>
            {t("conditionsOfUse.intellectualPropertyRights.content")}
          </Paragraph>

          <SubHeading>{t("conditionsOfUse.userAccounts.title")}</SubHeading>
          <Paragraph>{t("conditionsOfUse.userAccounts.content")}</Paragraph>

          <SubHeading>
            {t("conditionsOfUse.behaviorRestrictions.title")}
          </SubHeading>
          <Paragraph>
            {t("conditionsOfUse.behaviorRestrictions.content")}
          </Paragraph>

          <SubHeading>
            {t("conditionsOfUse.liabilityLimitation.title")}
          </SubHeading>
          <Paragraph>
            {t("conditionsOfUse.liabilityLimitation.content")}
          </Paragraph>

          <SubHeading>
            {t("conditionsOfUse.disputeResolution.title")}
          </SubHeading>
          <Paragraph>
            {t("conditionsOfUse.disputeResolution.content")}
          </Paragraph>

          <SubHeading>
            {t("conditionsOfUse.termsModification.title")}
          </SubHeading>
          <Paragraph>
            {t("conditionsOfUse.termsModification.content")}
          </Paragraph>

          <SubHeading>{t("conditionsOfUse.termination.title")}</SubHeading>
          <Paragraph>{t("conditionsOfUse.termination.content")}</Paragraph>

          <Paragraph>
            For more information or questions, please contact{" "}
            <Link href="mailto:support@appflavors.com">
              support@appflavors.com
            </Link>
            .
          </Paragraph>
        </Content>
      </Container>
    </ThemeProvider>
  );
};

export default ConditionsOfUsePage;
