import React from "react";

const PlayStoreIconSvg: React.FC = () => (
  <svg
    height="800px"
    width="800px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 512 512"
    xmlSpace="preserve"
  >
    <circle style={{ fill: "#273B7A" }} cx="256" cy="256" r="256" />
    <path
      style={{ fill: "#121149" }}
      d="M322.965,503.132c75.369-20.373,136.947-74.295,167.727-144.763L380.449,248.125l-80.363,40.465
	l-13.941-13.939l-95.741,95.92L322.965,503.132z"
    />
    <path
      style={{ fill: "#FFC100" }}
      d="M184.879,152.521v206.958c0,10.864,11.907,17.527,21.168,11.843l169.746-104.195
	c8.868-5.444,8.828-18.344-0.072-23.731L205.972,140.633C196.71,135.025,184.879,141.695,184.879,152.521z"
    />
    <path
      style={{ fill: "#D8E86D" }}
      d="M195.387,372.964L337.022,219.97l-131.051-79.338c-9.261-5.608-21.094,1.062-21.094,11.888v206.958
	C184.879,366.313,189.588,371.479,195.387,372.964z"
    />
    <path
      style={{ fill: "#F52B2B" }}
      d="M206.045,371.322l116.527-71.527l14.45-8.87L195.319,139.054c-5.77,1.505-10.442,6.665-10.442,13.467
	v206.958C184.879,370.343,196.786,377.006,206.045,371.322z"
    />
    <path
      style={{ fill: "#00B7AD" }}
      d="M195.387,372.964l108.661-117.376l-108.73-116.533c-5.77,1.505-10.442,6.665-10.442,13.467v206.958
	C184.879,366.313,189.588,371.479,195.387,372.964z"
    />
  </svg>
);

export default PlayStoreIconSvg;
