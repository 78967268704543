import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box; // Assure que padding et border sont inclus dans la dimension totale des éléments
  }

  html, body, #root {
    margin: 0;
    padding: 0;
    overflow-x: hidden; // Empêche le défilement horizontal
    height: 100%; // Pleine hauteur pour <html>, <body>, et <div id="root">
  }

  body {
    font-family: 'Roboto', sans-serif; // Police par défaut
    background-color: #000; // Couleur de fond par défaut
    color: #fff; // Couleur de texte par défaut
    -webkit-font-smoothing: antialiased; // Améliore la netteté du texte sur les navigateurs WebKit (Chrome, Safari)
    -moz-osx-font-smoothing: grayscale; // Améliore la netteté du texte sur Firefox
  }

  a {
    color: #007bff; // Couleur par défaut des liens
    text-decoration: none; // Supprime le soulignement par défaut
    &:hover {
      text-decoration: underline; // Souligne les liens au survol
    }
  }
`;

export default GlobalStyle;
