import React, { useState } from "react";
import ImageSection from "./ImageSection/ImageSection";
import About from "./AboutSection/AboutSection";
import OurApplications from "./OurApplicationsSection/OurApplicationsSection";
import JobSection from "./JobSection/JobSection";
import { baseTheme } from "../../../theme/Theme";
import { ThemeProvider } from "styled-components";

const HomePage: React.FC = () => {
  const [isAcceptingProjects] = useState(true);
  const [isRecruiting] = useState(false);
  const homePageTheme = baseTheme.getHomePageTheme();

  return (
    <>
      <ThemeProvider theme={homePageTheme}>
        <ImageSection />
        <About />
        <OurApplications />
        <JobSection
          isAcceptingProjects={isAcceptingProjects}
          isRecruiting={isRecruiting}
        />
      </ThemeProvider>
    </>
  );
};

export default HomePage;
