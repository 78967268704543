import React from "react";
import styled, { css, keyframes } from "styled-components";
import JobSectionBackgroundImage from "../../../../assets/images/about-background.png";
import FreelanceImage from "../../../../assets/images/freelance.webp";
import RecrutementImage from "../../../../assets/images/recrutement.webp";
import ProjectIconSvg from "../../../../assets/Icons/ProjectIconSvg";
import MailIconSvg from "../../../../assets/Icons/MailIconSvg";
import { useTranslation } from "react-i18next";

const fadeInAnimation = keyframes`
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
`;

const fadeIn = css`
  animation: ${fadeInAnimation} 0.5s ease-out;
`;

const ContentWrapper = styled.div`
  ${fadeIn};
  display: flex;
  justify-content: space-between;
  gap: 24px;
  width: 100%;
  max-width: 1200px;
  margin: auto;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.colors.JobSectionBackground};
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.JobSectionImageShadow};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px;
  overflow: hidden;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows.JobSectionButtonShadow};
  }

  @media (max-width: 768px) {
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;

const JobSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 24px;
  background-image: linear-gradient(
      153deg,
      rgba(240, 240, 240, 0.9) 50%,
      rgba(255, 255, 255, 0.9) 50%
    ),
    url(${JobSectionBackgroundImage});
  background-size: cover;
  background-position: center;
  color: ${({ theme }) => theme.colors.JobSectionText};

  @media (max-width: 768px) {
    height: auto;
  }
`;

const SectionHeader = styled.h1`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.JobSectionPrimary};
  text-align: center;
  margin-bottom: 24px; // Exemple de taille de margin
  margin-top: 24px; // Exemple de taille de margin
`;

const TextContainer = styled.div`
  padding: 12px; // Exemple de taille de padding
`;

const Heading = styled.h2`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.JobSectionPrimary};
  margin-bottom: 8px; // Exemple de taille de margin
`;

const SubHeading = styled.h3`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.JobSectionSecondary};
  margin-bottom: 8px; // Exemple de taille de margin
`;

const Paragraph = styled.p`
  font-size: 0.9rem;
  margin-bottom: 12px; // Exemple de taille de margin
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 8px 0; // Exemple de taille de margin
`;

const ListItem = styled.li`
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  margin-bottom: 8px; // Exemple de taille de margin
  &:before {
    content: "";
    display: inline-block;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.JobSectionPrimary};
    margin-right: 8px; // Exemple de taille de margin
  }
`;

const ActionButton = styled.a<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ disabled, theme }) =>
    disabled
      ? theme.colors.JobSectionSecondary
      : theme.colors.JobSectionPrimary};
  color: ${({ theme }) => theme.colors.JobSectionBackground};
  padding: 12px; // Exemple de taille de padding
  border-radius: 0 0 8px 8px;
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: bold;
  width: 100%;
  box-sizing: border-box;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  transition: background-color 0.3s;

  &:hover {
    background-color: ${({ theme }) =>
      theme.gradients.JobSectionButtonGradient};
  }

  svg {
    width: 24px;
    height: 24px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  padding: 12px; // Exemple de taille de padding
  img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    object-fit: cover;
    max-height: 32vh;
  }
`;

interface JobSectionProps {
  isRecruiting: boolean;
  isAcceptingProjects: boolean;
}

interface JobSectionProps {
  isRecruiting: boolean;
  isAcceptingProjects: boolean;
}

interface JobSectionProps {
  isRecruiting: boolean;
  isAcceptingProjects: boolean;
}

const JobSection: React.FC<JobSectionProps> = ({
  isRecruiting,
  isAcceptingProjects,
}) => {
  const { t } = useTranslation();

  return (
    <JobSectionContainer>
      <SectionHeader>{t("jobSection.header")}</SectionHeader>
      <ContentWrapper>
        <Column>
          <ImageWrapper>
            <img src={FreelanceImage} alt={t("jobSection.freelanceAlt")} />
          </ImageWrapper>
          <TextContainer>
            <Heading>{t("jobSection.freelanceHeading")}</Heading>
            <SubHeading>{t("jobSection.freelanceSubHeading")}</SubHeading>
            <Paragraph>{t("jobSection.freelanceParagraph")}</Paragraph>
            <List>
              <ListItem>{t("jobSection.freelanceListItem1")}</ListItem>
              <ListItem>{t("jobSection.freelanceListItem2")}</ListItem>
              <ListItem>{t("jobSection.freelanceListItem3")}</ListItem>
            </List>
          </TextContainer>
          <ActionButton
            href={isAcceptingProjects ? "mailto:support@appflavors.com" : "#"}
            disabled={!isAcceptingProjects}
          >
            {isAcceptingProjects ? <ProjectIconSvg /> : null}
            {t("jobSection.freelanceActionButton")}
          </ActionButton>
        </Column>
        <Column>
          <ImageWrapper>
            <img src={RecrutementImage} alt={t("jobSection.recruitmentAlt")} />
          </ImageWrapper>
          <TextContainer>
            <Heading>{t("jobSection.recruitmentHeading")}</Heading>
            <SubHeading>{t("jobSection.recruitmentSubHeading")}</SubHeading>
            <Paragraph>{t("jobSection.recruitmentParagraph")}</Paragraph>
            <List>
              <ListItem>{t("jobSection.recruitmentListItem1")}</ListItem>
              <ListItem>{t("jobSection.recruitmentListItem2")}</ListItem>
              <ListItem>{t("jobSection.recruitmentListItem3")}</ListItem>
            </List>
          </TextContainer>
          <ActionButton
            href={isRecruiting ? "mailto:support@appflavors.com" : "#"}
            disabled={!isRecruiting}
          >
            {isRecruiting && <MailIconSvg />}
            {t("jobSection.recruitmentActionButton")}
          </ActionButton>
        </Column>
      </ContentWrapper>
    </JobSectionContainer>
  );
};

export default JobSection;
