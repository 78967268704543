import styled, { ThemeProvider } from "styled-components";
import { format } from "date-fns";
import InstagramIconSvg from "../../../assets/Icons/InstagranIconSvg";
import XtwitterIconSvg from "../../../assets/Icons/XtwitterIconSvg";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { baseTheme } from "../../../theme/Theme";

const StyledRouterLink = styled(RouterLink)`
  // Utilisation de RouterLink comme nom renommé pour Link
  color: ${({ theme }) => theme.colors.link};
  text-decoration: none;
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.linkHover};
  }
`;

const FooterContainer = styled.footer`
  background-color: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  padding: 40px 20px;
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const FooterContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: 20px;
  width: 100%;
  max-width: 1200px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const FooterSection = styled.section`
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 12px;
  padding: 20px;
  width: calc(33.333% - 20px);
  height: 150px;
  flex: 1;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    box-shadow: none;
  }
`;

const FooterSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
`;

const SocialLinksWrapper = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
`;

const LegalLinksWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ContactWrapper = styled.address`
  text-align: center;
`;

const Link = styled.a`
  color: ${({ theme }) => theme.colors.link};
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  &:hover,
  &:focus {
    color: ${({ theme }) => theme.colors.linkHover};
    outline: none;
  }
`;

const EmailLink = styled(Link)`
  font-size: 16px;
  font-weight: bold;
`;

const IconWrapper = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.icon};
  background-color: ${({ theme }) => theme.colors.socialIconBackground};
  svg {
    width: 24px;
    height: 24px;
    fill: currentColor;
  }
`;

const Divider = styled.hr`
  border: none;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.divider};
  width: 80%;
  margin: 40px 0;
`;

const SectionTitle = styled.h3`
  text-align: center;
  font-size: 18px;
  margin-bottom: 15px;
`;

const CopyrightText = styled.p`
  text-align: center;
  font-size: 14px;
  width: 100%;
`;

const InstagramIconWrapper = styled(IconWrapper)`
  svg {
    transform: scale(1.45);
  }
`;

const Footer = () => {
  const currentYear = useMemo(() => format(new Date(), "yyyy"), []);
  const { t } = useTranslation();
  const footerTheme = baseTheme.getFooterTheme();

  return (
    <ThemeProvider theme={footerTheme}>
      <FooterContainer>
        <FooterContent>
          <FooterSection>
            <SectionTitle>{t("footer.followUs")}</SectionTitle>
            <FooterSectionContent>
              <SocialLinksWrapper>
                <InstagramIconWrapper
                  as="a"
                  href="https://www.instagram.com"
                  aria-label={t("footer.followInstagram")}
                >
                  <InstagramIconSvg />
                </InstagramIconWrapper>
                <IconWrapper
                  as="a"
                  href="https://twitter.com"
                  aria-label={t("footer.followTwitter")}
                >
                  <XtwitterIconSvg />
                </IconWrapper>
              </SocialLinksWrapper>
            </FooterSectionContent>
          </FooterSection>
          <FooterSection>
            <SectionTitle>{t("footer.legal")}</SectionTitle>
            <FooterSectionContent>
              <LegalLinksWrapper>
                <StyledRouterLink to="/privacy-policy">
                  {t("footer.privacyPolicy")}
                </StyledRouterLink>
                <StyledRouterLink to="/terms-of-use">
                  {t("footer.termsOfUse")}
                </StyledRouterLink>
                <StyledRouterLink to="/legal-notice">
                  {t("footer.legalNotice")}
                </StyledRouterLink>
              </LegalLinksWrapper>
            </FooterSectionContent>
          </FooterSection>
          <FooterSection>
            <SectionTitle>{t("footer.contact")}</SectionTitle>
            <FooterSectionContent>
              <ContactWrapper>
                <EmailLink href="mailto:support@appflavors.com">
                  {t("footer.email")}
                </EmailLink>
              </ContactWrapper>
            </FooterSectionContent>
          </FooterSection>
        </FooterContent>
        <Divider />
        <FooterContent>
          <CopyrightText>
            © {currentYear} AppFlavors. {t("footer.allRightsReserved")}
          </CopyrightText>
        </FooterContent>
      </FooterContainer>
    </ThemeProvider>
  );
};

export default Footer;
