import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import routesConfig from "../../../../routes/routesConfig";
import LanguageSelector from "./DesktopLanguageSelector";

const HeaderContainer = styled.header<{ $isVisible: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: rgba(0, 0, 0, 0.8);
  color: ${({ theme }) => theme.colors.headerText};
  width: 100%;
  z-index: 1000;
  position: fixed;
  top: 0;
  transition:
    top 0.3s,
    box-shadow 0.3s;
  ${({ $isVisible }) =>
    $isVisible ? "box-shadow: 0 2px 4px rgba(0,0,0,0.2);" : "box-shadow: none;"}
`;

const Logo = styled.div`
  color: ${({ theme }) => theme.colors.logoText};
  font-weight: bold;
  font-size: 1.5rem;
  span {
    color: ${({ theme }) => theme.colors.logoSpanText};
  }
`;

const NavLinkStyled = styled(NavLink)`
  color: ${({ theme }) => theme.colors.navLinkText};
  padding: 0.5rem 1rem;
  font-weight: 500;
  text-decoration: none !important;
  &:hover {
    color: ${({ theme }) => theme.colors.navLinkHover};
  }
  &.active {
    color: ${({ theme }) => theme.colors.navLinkActive};
    text-decoration: underline;
  }
`;

const NavigationLinks = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const DesktopHeader: React.FC = () => {
  const { t } = useTranslation();
  const headerRoutes = routesConfig.filter(
    ({ location }) => location === "header" || location === "both",
  );

  const [lastScrollY, setLastScrollY] = useState<number>(window.scrollY);
  const [visible, setVisible] = useState<boolean>(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY && currentScrollY > 50) {
        setVisible(false);
      } else if (currentScrollY < lastScrollY) {
        setVisible(true);
      }

      setLastScrollY(currentScrollY);
    };

    const throttledHandleScroll = throttle(handleScroll, 100);

    window.addEventListener("scroll", throttledHandleScroll);
    return () => window.removeEventListener("scroll", throttledHandleScroll);
  }, [lastScrollY, visible]);

  function throttle(
    func: (...args: any[]) => void,
    limit: number,
  ): (...args: any[]) => void {
    let inThrottle: boolean;
    return function (this: void, ...args: any[]) {
      if (!inThrottle) {
        func.apply(this, args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  }

  return (
    <HeaderContainer $isVisible={visible}>
      <Logo>
        APP<span>FLAVORS</span>
      </Logo>
      <NavigationLinks>
        {headerRoutes.map(({ path, key }) => (
          <NavLinkStyled
            key={key}
            to={path}
            className={({ isActive }) => (isActive ? "active" : "")}
          >
            {t(`routes.${key}`)}
          </NavLinkStyled>
        ))}
      </NavigationLinks>
      <LanguageSelector />
    </HeaderContainer>
  );
};

export default DesktopHeader;
