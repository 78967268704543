import React from "react";
import { Helmet } from "react-helmet-async";

import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import GlobalStyle from "../theme/globalStyles";
import AppRoutes from "../routes/AppRoutes";
import styled, { ThemeProvider } from "styled-components";
import { baseTheme } from "../theme/Theme";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
`;

const App: React.FC = () => {
  return (
    <>
      <GlobalStyle />
      <Helmet>
        <html lang="en" />
        <title>AppFlavors</title>
        <meta
          name="description"
          content="Explore AppFlavors: Your gateway to innovative mobile apps and games that light up your digital world."
        />
        <meta property="og:title" content="AppFlavors" />
        <meta
          property="og:description"
          content="Explore AppFlavors: Your gateway to innovative mobile apps and games that light up your digital world."
        />
        <meta property="og:type" content="website" />
        <link rel="icon" href="" />
        <link rel="icon" href="/favicon.ico" type="image/x-icon"></link>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          httpEquiv="Strict-Transport-Security"
          content="max-age=31536000; includeSubDomains"
        />
      </Helmet>

      <ThemeProvider theme={baseTheme}>
        {" "}
        {/* Utilisez le thème de base ou un thème spécifique selon le contexte */}
        <PageContainer>
          <Header />
          <AppRoutes />
          <Footer />
        </PageContainer>
      </ThemeProvider>
    </>
  );
};

export default App;
