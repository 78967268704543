import { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import MobileLanguageSelector from "./MobileLanguageSelector";
import { NavLink } from "react-router-dom";
import routesConfig from "../../../../routes/routesConfig";

// Ajoutez cette interface pour définir le type des props, incluant $isOpen
interface NavLinksContainerProps {
  $isOpen: boolean;
}

// Utilisez cette interface dans la définition de votre styled component
const NavLinksContainer = styled.nav<NavLinksContainerProps>`
  display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => `${theme.colors.primary}dd`};
  padding: 1rem;
  color: ${({ theme }) => theme.colors.quaternary};
  text-align: center;
`;

// Le reste de votre code reste inchangé
const MobileHeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => `${theme.colors.primary}dd`};
  color: ${({ theme }) => theme.colors.quaternary};
  padding: 0.5rem 1rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1050;
`;

const SiteTitle = styled.h1`
  color: ${({ theme }) =>
    `${theme.colors.secondary}cc`}; // Rouge avec transparence
  margin: 0;
  font-size: 1.2rem;
`;

const MenuToggle = styled.button`
  font-size: 1.5rem;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
`;

const StyledNavLink = styled(NavLink)`
  display: block;
  color: ${({ theme }) => theme.colors.quaternary};
  padding: 0.5rem;
  text-decoration: none;
  &:hover,
  &.active {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const MobileNav = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <MobileHeaderContainer>
      <MenuToggle onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? "✖" : "☰"}
      </MenuToggle>
      <SiteTitle>APPFLAVORS</SiteTitle>
      <NavLinksContainer $isOpen={isOpen}>
        {routesConfig
          .filter(
            (route) => route.location === "header" || route.location === "both",
          )
          .map((route) => (
            <StyledNavLink
              key={route.key}
              to={route.path}
              onClick={() => setIsOpen(false)}
            >
              {t(`routes.${route.key}`)}
            </StyledNavLink>
          ))}
        <MobileLanguageSelector />
      </NavLinksContainer>
    </MobileHeaderContainer>
  );
};

export default MobileNav;
