import styled from "styled-components";
import { useTranslation } from "react-i18next";
import languagesConfig from "../../../../i18n/languagesConfig";

const LanguageSelectorContainer = styled.div`
  position: relative;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LanguageButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const Dropdown = styled.div`
  position: absolute;
  top: calc(100% + 5px);
  background-color: ${({ theme }) =>
    theme.colors.primary}; // Utilisation de la couleur quaternaire pour le fond
  border: 1px solid ${({ theme }) => theme.colors.tertiary}; // Utilisation de la couleur tertiaire pour la bordure
  border-radius: 5px;
  padding: 5px;
  display: none;
  flex-direction: column;
  box-shadow: ${({ theme }) =>
    theme.shadows
      .buttonShadow}; // Utilisation de l'ombre des boutons pour le menu déroulant
  ${LanguageSelectorContainer}:hover & {
    display: flex;
  }
`;

const DropdownItem = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.secondary}; // Changement de la couleur de fond au survol
    color: ${({ theme }) =>
      theme.colors.quaternary}; // Changement de la couleur du texte au survol
  }
`;

const FlagContainer = styled.div`
  width: 20px;
  height: 20px;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const MobileLanguageSelector = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  return (
    <LanguageSelectorContainer>
      {languagesConfig[currentLanguage] && (
        <LanguageButton>
          <FlagContainer>{languagesConfig[currentLanguage].flag}</FlagContainer>
        </LanguageButton>
      )}
      <Dropdown>
        {Object.entries(languagesConfig).map(([langCode, { label, flag }]) => (
          <DropdownItem key={langCode} onClick={() => changeLanguage(langCode)}>
            <FlagContainer>{flag}</FlagContainer>
            <span style={{ marginLeft: "10px" }}>{label}</span>
          </DropdownItem>
        ))}
      </Dropdown>
    </LanguageSelectorContainer>
  );
};

export default MobileLanguageSelector;
