import HomePage from "../components/pages/HomePage/HomePage";
import PrivacyPolicyPage from "../components/pages/PrivacyPolicyPage/PrivacyPolicyPage";
import LegalNoticePage from "../components/pages/LegalNoticePage/LegalNoticePage";
import ConditionsOfUsePage from "../components/pages/ConditionsOfUsePage/ConditionsOfUsePage";
import NotFoundPage from "../components/pages/NotFoundPage";
import React from "react";

export type SubMenuItem = {
  path: string;
  key: string;
};

export type RouteLocation = "header" | "footer" | "both" | "none";

export type RouteConfig = {
  path: string;
  component: React.ComponentType;
  key: string;
  location: RouteLocation;
  subMenu?: SubMenuItem[];
};

const routesConfig: RouteConfig[] = [
  { path: "/", component: HomePage, key: "home", location: "header" },
  /*    { path: "/about", component: AboutPage, key: "about", location: 'header' },
    { path: "/games", component: GamesPage, key: "games", location: 'header' },
    { path: "/games/:gameId", component: GameDetailsPage, key: "gameDetails", location: 'none' },
    { path: "/contact", component: ContactPage, key: "contact", location: 'header' },
*/
  {
    path: "/privacy-policy",
    component: PrivacyPolicyPage,
    key: "privacyPolicy",
    location: "both",
  },
  {
    path: "/conditions-of-use",
    component: ConditionsOfUsePage,
    key: "conditionsOfUse",
    location: "both",
  },
  {
    path: "/legal-notice",
    component: LegalNoticePage,
    key: "legalNotice",
    location: "both",
  },
  { path: "*", component: NotFoundPage, key: "notFound", location: "none" },
];

export default routesConfig;
