import { useState } from "react";
import styled from "styled-components";
import SlideComponent from "./SlideComponent";
import AboutBackgroundImage from "../../../../assets/images/about-inverted.png";
import { AnimatePresence } from "framer-motion";
import useSlidesData from "./SlideData";
import useWindowSize from "../../../../hooks/useWindowSize";

const SliderWrapper = styled.div<{ width: number }>`
  overflow: hidden;
  width: 100%;
  height: ${({ width }) => (width > 768 ? "100vh" : "auto")};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: relative;
  background: url(${AboutBackgroundImage}) no-repeat center center / cover;
  padding: ${({ width }) => (width <= 768 ? "10vh 5vw" : "0")};
  padding-left: ${({ width }) =>
    width <= 768 ? "5vw" : width > 1200 ? "10vw" : "50px"};
  padding-right: ${({ width }) =>
    width <= 768 ? "5vw" : width > 1200 ? "10vw" : "50px"};

  &:before {
    content: "";
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10%;
  position: static; // changement important pour permettre l'alignement dans le conteneur
  bottom: 10px; // ajustez selon le besoin
  width: 80%; // ajustez selon le besoin
  left: 10%; // centré par rapport au SliderWrapper
  z-index: 2;
`;

const NavigationButton = styled.div<{ $side: "left" | "right" }>`
  position: absolute;
  bottom: 30px;
  ${({ $side }) => ($side === "left" ? "left: 10%;" : "right: 10%;")}
  z-index: 2;
  background-color: ${({ theme }) => theme.colors.ourApplicationsNavButton};
  z-index: 2;
  color: white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 30px;
  border: 2px solid #fff;
  box-shadow: ${({ theme }) => theme.shadows.ourApplicationsNavButtonShadow};

  &:hover {
    background-color: ${({ theme }) =>
      theme.colors.ourApplicationsNavButtonHover};
  }
`;

const Dot = styled.span<{ $active: boolean }>`
  padding: 5px;
  margin-right: 5px;
  cursor: pointer;
  border-radius: 50%;
  background: ${({ $active, theme }) =>
    $active
      ? theme.colors.ourApplicationsActiveDot
      : theme.colors.ourApplicationsDot};
`;

const DotsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const OurApplications = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { width } = useWindowSize();
  const slidesData = useSlidesData();

  const [direction, setDirection] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((index) => (index + 1) % slidesData.length);
    setDirection(1);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (index) => (index - 1 + slidesData.length) % slidesData.length,
    );
    setDirection(-1);
  };

  return (
    <SliderWrapper width={width}>
      <AnimatePresence>
        <SlideComponent
          key={slidesData[currentIndex].id}
          slideData={slidesData[currentIndex]}
          direction={direction}
        />
      </AnimatePresence>
      <NavigationContainer>
        <NavigationButton $side="left" onClick={prevSlide}>
          &#10094;
        </NavigationButton>
        <DotsContainer>
          {slidesData.map((slide, index) => (
            <Dot
              key={slide.id}
              $active={index === currentIndex}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </DotsContainer>
        <NavigationButton $side="right" onClick={nextSlide}>
          &#10095;
        </NavigationButton>
      </NavigationContainer>
    </SliderWrapper>
  );
};

export default OurApplications;
