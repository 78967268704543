import React from "react";
import styled, { keyframes } from "styled-components";
import { SlideData } from "./SlideData";
import PlayStoreIconSvg from "../../../../assets/Icons/PlayStoreIconSvg";
import AppStoreIconSvg from "../../../../assets/Icons/AppStoreIconSvg";
import useWindowSize from "../../../../hooks/useWindowSize";
import { useTranslation } from "react-i18next";

const slideInRight = keyframes`
  0% { transform: translateX(100%); }
  100% { transform: translateX(0); }
`;

const slideInLeft = keyframes`
  0% { transform: translateX(-100%); }
  100% { transform: translateX(0); }
`;

const SlideContainer = styled.div<{ direction: number; width: number }>`
  width: ${({ width }) => (width > 768 ? "85vw" : "100vw")};
  max-width: 1200px;
  height: auto;
  display: flex;
  flex-direction: ${({ width }) => (width > 768 ? "row" : "column")};
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.slideComponentBackground};
  border-radius: 15px;
  box-shadow: ${({ theme }) => theme.shadows.slideComponentBoxShadow};
  overflow: hidden;
  position: relative;
  animation: ${({ direction }) =>
      direction === 1 ? slideInRight : slideInLeft}
    0.8s ease-in-out forwards;

  @media (max-width: 768px) {
    margin: 0;
    width: 90%;
    height: 80vh;
  }
`;

const ImageContainer = styled.div<{ $imageUrl: string; width: number }>`
  width: ${({ width }) => (width > 768 ? "60%" : "100%")};
  height: ${({ width }) => (width > 768 ? "100%" : "300px")};
  background: url(${({ $imageUrl }) => $imageUrl}) no-repeat center center;
  background-size: cover;

  @media (max-width: 768px) {
    height: 25vh;
    width: 100%;
    background-size: cover;
  }
`;

const DetailsContainer = styled.div<{ width: number }>`
  width: ${({ width }) => (width > 768 ? "45%" : "100%")};
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ width }) => (width > 768 ? "flex-start" : "center")};
  text-align: ${({ width }) => (width > 768 ? "left" : "center")};
  background: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(5px);

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  color: ${({ theme }) => theme.colors.slideComponentText};
  margin-bottom: 1.2rem;

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.slideComponentSubtitle};
  margin-bottom: 1.2rem;
  font-style: italic;
  opacity: 0.9;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Description = styled.p`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.slideComponentText};
  margin-bottom: 2rem;
  line-height: 1.7;
  text-align: justify;
  hyphens: auto;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const StoreLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
`;

const StoreButtonBase = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  margin: 10px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: ${({ theme }) => theme.shadows.slideComponentBoxShadow};
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease,
    border 0.3s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.5);
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
`;

const PlayStoreButton = styled(StoreButtonBase)`
  border-color: #00a653;
  color: black;

  &:hover {
    border-color: #00c851;
  }
`;

const AppStoreButton = styled(StoreButtonBase)`
  border-color: #0070c9;
  color: black;

  &:hover {
    border-color: #40c4ff;
  }
`;

const StatusIndicator = styled.span<{ $status: string }>`
  display: inline-block;
  padding: 0.5em 1em;
  border-radius: 20px;
  color: #fff;
  font-size: 0.9em;
  font-weight: bold;
  background-color: ${({ $status, theme }) =>
    $status === "Disponible"
      ? theme.colors.slideComponentIndicator
      : theme.colors.slideComponentIndicatorSoon};
  margin-bottom: 1rem;
`;

const SlideComponent: React.FC<{ slideData: SlideData; direction: number }> = ({
  slideData,
  direction,
}) => {
  const { width } = useWindowSize();
  const { t } = useTranslation();

  return (
    <SlideContainer direction={direction} width={width}>
      <ImageContainer $imageUrl={slideData.imageUrl} width={width} />
      <DetailsContainer width={width}>
        <Title>{t(slideData.title)}</Title>
        <Subtitle>{t(slideData.subtitle)}</Subtitle>
        <StatusIndicator $status={t(slideData.status)}>
          {t(slideData.status)}
        </StatusIndicator>
        <Description>{t(slideData.description)}</Description>
        <StoreLinks>
          {slideData.status === "Disponible" ? (
            <>
              <AppStoreButton
                href={slideData.appStoreLink}
                target="_blank"
                aria-label="App Store Link"
              >
                <AppStoreIconSvg />
                App Store
              </AppStoreButton>
              <PlayStoreButton
                href={slideData.playStoreLink}
                target="_blank"
                aria-label="Play Store Link"
              >
                <PlayStoreIconSvg />
                Google Play
              </PlayStoreButton>
            </>
          ) : (
            <StatusIndicator $status={t("slides.availableSoon")}>
              {t("slides.availableSoon")}
            </StatusIndicator>
          )}
        </StoreLinks>
      </DetailsContainer>
    </SlideContainer>
  );
};

export default SlideComponent;
