import styled, { keyframes, ThemeProvider } from "styled-components";
import { useTranslation } from "react-i18next";
import { baseTheme } from "../../../theme/Theme";

// Animation
const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Thème spécifique pour la page de mentions légales
const legalNoticeTheme = {
  ...baseTheme, // Assurez-vous que baseTheme contient les couleurs et les polices nécessaires
  fonts: {
    primary: "'Helvetica Neue', Helvetica, Arial, sans-serif",
    secondary: "'Georgia', serif",
  },
  breakpoints: {
    tablet: "768px",
    desktop: "1024px",
  },
};

// Styled Components
const Container = styled.main`
  animation: ${fadeInUp} 1s ease-out;
  background-color: ${(props) => props.theme.colors.background};
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding: 80px 20px;
  width: 100%;
`;

const Title = styled.h1`
  color: ${(props) => props.theme.colors.secondary};
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: 3em;
  margin-bottom: 40px;
  @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    font-size: 2.5em;
  }
`;

const Content = styled.section`
  color: ${(props) => props.theme.colors.text};
  font-size: 1.2em;
  line-height: 1.7;
  max-width: 700px;
  padding: 0 15px;
  width: 100%;
`;

const SubHeading = styled.h2`
  color: ${(props) => props.theme.colors.secondary};
  font-size: 2em;
  margin-bottom: 20px;
  margin-top: 30px;
`;

const Paragraph = styled.p`
  margin-bottom: 15px;
`;

const Link = styled.a`
  color: ${(props) => props.theme.colors.secondary};
  &:hover {
    color: ${(props) => props.theme.colors.linkHover};
  }
`;

// Composant LegalNoticePage
const LegalNoticePage = () => {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={legalNoticeTheme}>
      <Container>
        <Title>{t("legalNotice.title")}</Title>
        <Content>
          <SubHeading>{t("legalNotice.identification.title")}</SubHeading>
          <Paragraph>{t("legalNotice.identification.companyName")}</Paragraph>
          <Paragraph>{t("legalNotice.identification.legalForm")}</Paragraph>
          <Paragraph>
            {t("legalNotice.identification.legalRepresentative")}
          </Paragraph>
          <Paragraph>{t("legalNotice.identification.contact")}</Paragraph>

          <SubHeading>{t("legalNotice.websiteInfo.title")}</SubHeading>
          <Paragraph>{t("legalNotice.websiteInfo.url")}</Paragraph>
          <Paragraph>
            {t("legalNotice.websiteInfo.publicationDirector")}
          </Paragraph>

          <SubHeading>{t("legalNotice.hosting.title")}</SubHeading>
          <Paragraph>{t("legalNotice.hosting.details")}</Paragraph>

          <SubHeading>{t("legalNotice.intellectualProperty.title")}</SubHeading>
          <Paragraph>{t("legalNotice.intellectualProperty.content")}</Paragraph>

          <SubHeading>{t("legalNotice.liability.title")}</SubHeading>
          <Paragraph>{t("legalNotice.liability.content")}</Paragraph>

          <SubHeading>{t("legalNotice.law.title")}</SubHeading>
          <Paragraph>{t("legalNotice.law.content")}</Paragraph>

          <Paragraph>
            For more information, please visit{" "}
            <Link href="mailto:support@appflavors.com">
              support@appflavors.com
            </Link>
            .
          </Paragraph>
        </Content>
      </Container>
    </ThemeProvider>
  );
};

export default LegalNoticePage;
