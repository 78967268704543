import App1Image from "../../../../assets/images/apps/drinkz.webp";
import App2Image from "../../../../assets/images/apps/historite.webp";
import App3Image from "../../../../assets/images/apps/mars.webp";
import { useTranslation } from "react-i18next";

export interface SlideData {
  id: string;
  imageUrl: string;
  title: string;
  subtitle: string;
  description: string;
  appStoreLink: string;
  playStoreLink: string;
  status: "Prochainement" | "Disponible";
}

const useSlidesData = (): SlideData[] => {
  const { t } = useTranslation();

  return [
    {
      id: "1",
      imageUrl: App3Image,
      title: t("slides.marsAdventure.title"),
      subtitle: t("slides.marsAdventure.subtitle"),
      description: t("slides.marsAdventure.description"),
      appStoreLink: "",
      playStoreLink: "",
      status: t("slides.marsAdventure.status"),
    },
    {
      id: "2",
      imageUrl: App2Image,
      title: t("slides.historite.title"),
      subtitle: t("slides.historite.subtitle"),
      description: t("slides.historite.description"),
      appStoreLink: "",
      playStoreLink: "",
      status: t("slides.historite.status"),
    },
    {
      id: "3",
      imageUrl: App1Image,
      title: t("slides.drinkz.title"),
      subtitle: t("slides.drinkz.subtitle"),
      description: t("slides.drinkz.description"),
      appStoreLink: "",
      playStoreLink: "",
      status: t("slides.drinkz.status"),
    },
  ];
};

export default useSlidesData;
