import React from "react";
import styled, { keyframes } from "styled-components";
import backgroundImage from "../../../../assets/images/hero-background.webp";
import useWindowSize from "../../../../hooks/useWindowSize";
import { useTranslation } from "react-i18next";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideUp = keyframes`
  from { transform: translateY(30px); }
  to { transform: translateY(0); }
`;

const shimmer = keyframes`
  from { transform: scale(1); opacity: 0.7; }
  to { transform: scale(1.05); opacity: 1; }
`;

const SectionContainer = styled.section<{ width: number }>`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: url(${backgroundImage}) no-repeat center bottom;
  background-size: cover;
  position: relative;
  overflow: hidden;
  width: 100%;

  @media (max-width: 768px) {
    height: 100vh;
    padding: 20px;
    box-sizing: border-box; // Assurez-vous que le padding ne dépasse pas les dimensions de la section
  }
`;

const Card = styled.div`
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(10px);
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(255, 0, 0, 0.5);
  text-align: center;
  position: relative;
  animation: ${fadeIn} 1s ease-in-out;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Title = styled.h1`
  font-size: 3rem;
  color: #ff0000;
  margin-bottom: 1rem;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
  animation: ${slideUp} 1.5s ease-in-out;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Subtitle = styled.h2`
  font-size: 1.8rem;
  color: #ffffff;
  margin-bottom: 2rem;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.8);
  animation: ${slideUp} 2s ease-in-out;

  @media (max-width: 768px) {
    font-size: 1.4rem;
  }
`;

const CallToActionButton = styled.a`
  padding: 15px 30px;
  background: linear-gradient(45deg, #ff0000, #660000);
  color: #ffffff;
  text-decoration: none;
  border-radius: 8px;
  font-weight: bold;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  animation: ${shimmer} 2.5s ease-in-out infinite;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);

  &:hover {
    background: linear-gradient(45deg, #660000, #ff0000);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
    font-size: 1rem;
  }
`;

const ImageSection: React.FC = () => {
  const { width } = useWindowSize();
  const { t } = useTranslation();

  return (
    <SectionContainer width={width}>
      <Card>
        <Title>{t("heroSection.title")}</Title>
        <Subtitle>{t("heroSection.subtitle")}</Subtitle>
        <CallToActionButton href="#discover">
          {t("heroSection.callToAction")}
        </CallToActionButton>
      </Card>
    </SectionContainer>
  );
};

export default ImageSection;
