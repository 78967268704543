import styled from "styled-components";
import AboutImage from "../../../../assets/images/background.webp";
import AboutBackgroundImage from "../../../../assets/images/about-background.png";
import { useTranslation } from "react-i18next";

const AboutContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background:
    linear-gradient(
      153deg,
      ${({ theme }) => theme.colors.gradientStart} 50%,
      ${({ theme }) => theme.colors.gradientEnd} 50%
    ),
    url(${AboutBackgroundImage}) no-repeat center/cover;
  padding: 20px;
  text-align: center;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: left;
  }
`;

const ImageContainer = styled.div`
  width: 80%;
  max-width: 300px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    width: 50%;
    max-width: 600px;
    margin-bottom: 0;
  }
`;

const TextContainer = styled.div`
  @media (min-width: 768px) {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const Title = styled.h2`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.titleColor};
  margin: 0 0 1rem;
`;

const Paragraph = styled.p`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.textColor};
  line-height: 1.4;
  margin: 0 0 1rem;
`;

const AboutButton = styled.a`
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.buttonTextColor};
  background: ${({ theme }) =>
    `linear-gradient(145deg, ${theme.colors.buttonStartColor}, ${theme.colors.buttonEndColor})`};
  border-radius: 5px;
  text-decoration: none;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const About = () => {
  const { t } = useTranslation();

  return (
    <AboutContainer>
      <ImageContainer>
        <StyledImage src={AboutImage} alt={t("about.alt")} />
      </ImageContainer>
      <TextContainer>
        <Title>{t("about.title")}</Title>
        <Paragraph>{t("about.paragraph1")}</Paragraph>
        <Paragraph>{t("about.paragraph2")}</Paragraph>
        <AboutButton href="#more-about">{t("about.buttonText")}</AboutButton>
      </TextContainer>
    </AboutContainer>
  );
};

export default About;
